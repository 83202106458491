<template>
  <b-modal
    :id="'modalPrice' + order.id"
    :ref="'modalPrice' + order.id"
    class="order-modal-price"
    :title="$t('DASHBOARD_ORDER_PRICE_TITLE', { orderId: order.code })"
    centered
    hide-footer
    @shown="onModalPriceShown(order)"
    @hidden="onModalPriceHidden(order)"
  >
    <div class="order-modal-price-body">
      <div
        v-if="
          !isOrdersFetching && !isConfiguratorFetching && orderSelectedItem && $store.state.configurator.actualModel
        "
      >
        <div class="row mb-1">
          <div class="col-6 text-left">{{ $t('CFG_SUMMARY_CONFIGURATION') }}</div>
          <div class="col-6 text-right">{{ currentModelPrice(orderSelectedItem.discount) | price }}</div>
        </div>
        <div class="row mb-1">
          <div class="col-6 text-left">{{ $t('CFG_SUMMARY_TABLE_NASCAR_CUFFS') }}</div>
          <div class="col-6 text-right" v-if="configuratorNascarCuffs">
            {{ nascarCuffsPrice(orderSelectedItem.discount) | price }}
          </div>
          <div class="col-6 text-right" v-if="!configuratorNascarCuffs">-</div>
        </div>
        <div class="row mb-1">
          <div class="col-6 text-left">{{ $t('CFG_SUMMARY_MEASURES') }}</div>
          <div class="col-6 text-right" v-if="!isCustomizedSize">-</div>
          <div class="col-6 text-right" v-if="isCustomizedSize">
            {{ sizePrice(orderSelectedItem.discount) | price }}
          </div>
        </div>
        <div class="row mb-1">
          <div class="col-4 text-left">{{ $t('CFG_SUMMARY_LOGOS') }}</div>

          <div class="col-4 text-left" v-if="$store.state.configurator.currentLogoMode === 0">
            {{ $t('CFG_SUMMARY_TABLE_LOGOS_MODE_PRINT') }}
          </div>
          <div class="col-4 text-left" v-if="$store.state.configurator.currentLogoMode === 1">
            {{ $t('CFG_SUMMARY_TABLE_LOGOS_MODE_EMBROIDERY') }}
          </div>

          <div class="col-4 text-right">
            {{ logosPrice(orderSelectedItem.discount).logoSurface.toFixed(2) | price }}
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-4 text-left">&nbsp;</div>
          <div class="col-4 text-left">{{ $t('CFG_SUMMARY_DIGITALIZATION') }}</div>
          <div class="col-4 text-right">
            {{ logosPrice(orderSelectedItem.discount).digitalization.toFixed(2) | price }}
          </div>
        </div>
        <div class="row">
          <div class="col-6 text-left">
            <strong>{{ $t('CFG_SUMMARY_PRICE') }}</strong>
          </div>
          <div class="col-6 text-right">
            <strong>{{ totalPrice(orderSelectedItem.discount) | price }}</strong>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'ModalPriceDealer',
  computed: {
    ...mapGetters([
      'isOrdersFetching',
      'isChangingDashboard',
      'isOrderDeleting',
      'ordersDefaultParams',
      'orderSelectedItem',
      'isConfiguratorFetching',
      'isCustomizedSize',
      'sizePrice',
      'logosPrice',
      'currentModelPrice',
      'nascarCuffsPrice',
      'configuratorNascarCuffs',
      'userCompany'
    ])
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  methods: {
    totalPrice(discountPercentage) {
      return (
        this.currentModelPrice(discountPercentage) +
        this.nascarCuffsPrice(discountPercentage) +
        this.logosPrice(discountPercentage).logoSurface +
        this.logosPrice(discountPercentage).digitalization +
        this.sizePrice(discountPercentage)
      ).toFixed(2)
    },
    onModalPriceShown(order) {
      this.$store.dispatch('CONFIGURATOR_GET_SETTINGS').then(() => {
        this.$store.dispatch('ORDERS_GET_ITEM', order.id).then(() => {
          if (this.orderSelectedItem && this.orderSelectedItem.configuration) {
            this.$store.dispatch('CONFIGURATOR_SELECT_MODEL', this.orderSelectedItem.configuration.sku).then(() => {
              this.$store.dispatch('CONFIGURATOR_IMPORT', { configuration: this.orderSelectedItem.configuration })
            })
          } else {
            this.$store.commit('ORDERS_ERROR')
            this.$store.commit('CONFIGURATOR_IS_FETCHING', false)
          }
        })
      })
    },
    onModalPriceHidden() {
      this.$store.dispatch('CONFIGURATOR_INITAL_VALUES')
      this.$store.dispatch('ORDERS_RESET_SELECTED')
    }
  }
}
</script>

<style scoped lang="scss">
.order-modal-price-body {
  min-height: 150px;
}
</style>
