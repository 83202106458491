<template>
  <div class="B2CDashboard">
    <div class="container-fluid">
      <div class="row">
        <div class="col-4">
          <h1 class="Page__title">{{ $t('DASHBOARD_TITLE_B2C') }}</h1>
        </div>
      </div>
      <div class="Filters row">
        <div class="col">
          <div class="Filters__form">
            <div class="row">
              <div class="col-12">
                <div class="row">
                  <div class="col">
                    <h2><Magnify /> {{ $t('DASHBOARD_FILTER_TITLE_SEARCH') }}</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <form @submit.prevent="filterSearch()">
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col-2">
                              <button
                                class="Filters__filter-type-btn cursor-pointer"
                                :class="{ selected: filterCategory === 'code' }"
                                type="button"
                                @click="filterCategory = 'code'"
                              >
                                {{ $t('DASHBOARD_FILTER_BY_ORDER') }}
                              </button>
                            </div>
                            <div class="col-2">
                              <button
                                class="Filters__filter-type-btn mr-2 mr-xl-4 cursor-pointer"
                                :class="{ selected: filterCategory === 'date' }"
                                type="button"
                                @click="filterCategory = 'date'"
                              >
                                {{ $t('DASHBOARD_FILTER_BY_DATE') }}
                              </button>
                            </div>
                            <div class="col-2">
                              <button
                                class="Filters__filter-type-btn mr-2 mr-xl-4 cursor-pointer"
                                :class="{ selected: filterCategory === 'consumerName' }"
                                type="button"
                                @click="filterCategory = 'consumerName'"
                              >
                                {{ $t('DASHBOARD_FILTER_BY_CONSUMER_NAME') }}
                              </button>
                            </div>
                            <div class="col-2">
                              <button
                                class="Filters__filter-type-btn mr-2 mr-xl-4 cursor-pointer"
                                :class="{ selected: filterCategory === 'consumerEmail' }"
                                type="button"
                                @click="filterCategory = 'consumerEmail'"
                              >
                                {{ $t('DASHBOARD_FILTER_BY_CONSUMER_EMAIL') }}
                              </button>
                            </div>
                            <div class="col-2">
                              <button
                                class="Filters__filter-type-btn mr-2 mr-xl-4 cursor-pointer"
                                :class="{ selected: filterCategory === 'consumerPhone' }"
                                type="button"
                                @click="filterCategory = 'consumerPhone'"
                              >
                                {{ $t('DASHBOARD_FILTER_BY_CONSUMER_PHONE') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <div class="row">
                            <div class="col-10">
                              <input
                                type="text"
                                class="form-control"
                                v-model="orderCode"
                                v-if="filterCategory === 'code'"
                                :placeholder="$t('DASHBOARD_FILTER_PLACEHOLDER_ORDER')"
                              />

                              <v-date-picker
                                v-if="filterCategory === 'date'"
                                v-model="dateRange"
                                mode="range"
                                popover-visibility="focus"
                                tint-color="#001489"
                                :is-double-paned="true"
                                :is-required="false"
                                :input-props="{
                                  class: 'form-control',
                                  placeholder: $t('DASHBOARD_FILTER_PLACEHOLDER_DATE'),
                                  readonly: true
                                }"
                              >
                              </v-date-picker>

                              <input
                                type="text"
                                class="form-control"
                                v-model="orderConsumerName"
                                v-if="filterCategory === 'consumerName'"
                                :placeholder="$t('DASHBOARD_FILTER_PLACEHOLDER_CONSUMER_NAME')"
                              />

                              <input
                                type="text"
                                class="form-control"
                                v-model="orderConsumerEmail"
                                v-if="filterCategory === 'consumerEmail'"
                                :placeholder="$t('DASHBOARD_FILTER_PLACEHOLDER_CONSUMER_EMAIL')"
                              />

                              <input
                                type="text"
                                class="form-control"
                                v-model="orderConsumerPhone"
                                v-if="filterCategory === 'consumerPhone'"
                                :placeholder="$t('DASHBOARD_FILTER_PLACEHOLDER_CONSUMER_PHONE')"
                              />
                            </div>
                            <div class="col-2">
                              <button class="Button Button__create Filters-submit cursor-pointer" type="submit">
                                {{ $t('ACTION_SEARCH') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="mt-4 Filters__set">
                      <div class="no-filter" v-if="noFiltersSet()">
                        {{ $t('DASHBOARD_FILTER_RECAP_NO_FILTER') }}
                      </div>
                      <div v-else>
                        <span v-if="queryString.code"
                          >{{ $t('DASHBOARD_FILTER_RECAP_PREFIX') }} {{ $t('DASHBOARD_FILTER_BY_ORDER') }}:
                          {{ queryString.code }}</span
                        >
                        <span v-if="queryString.createdAt || queryString.sentAt"
                          >{{ $t('DASHBOARD_FILTER_RECAP_PREFIX') }} {{ $t('DASHBOARD_FILTER_BY_DATE') }}:
                          {{ dateRangeFilter }}</span
                        >
                        <span v-if="queryString.consumerName"
                          >{{ $t('DASHBOARD_FILTER_RECAP_PREFIX') }} {{ $t('DASHBOARD_FILTER_BY_CONSUMER_NAME') }}:
                          {{ queryString.consumerName }}</span
                        >
                        <span v-if="queryString.consumerEmail"
                          >{{ $t('DASHBOARD_FILTER_RECAP_PREFIX') }} {{ $t('DASHBOARD_FILTER_BY_CONSUMER_EMAIL') }}:
                          {{ queryString.consumerEmail }}</span
                        >
                        <span v-if="queryString.consumerPhone"
                          >{{ $t('DASHBOARD_FILTER_RECAP_PREFIX') }} {{ $t('DASHBOARD_FILTER_BY_CONSUMER_PHONE') }}:
                          {{ queryString.consumerPhone }}</span
                        >
                        <!-- Reset Filters -->
                        <span class="reset-filter cursor-pointer pl-3" @click="filterCancel(true)"> <Delete /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="Table">
            <ErrorMessage v-if="ordersError && !isOrdersFetching" />
            <div v-if="!ordersError">
              <NoItemsFound v-if="ordersTotal === 0 && !isOrdersFetching" />
              <div v-if="ordersTotal > 0" v-show="!isChangingDashboard">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>
                        <input
                          type="checkbox"
                          :style="{ visibility: isSomeOrderDeleting ? 'hidden' : 'visible' }"
                          v-model="selectedOrdersAll"
                          @change="toggleSelectionOrders()"
                        />
                      </th>
                      <th scope="col">
                        <div @click="setSorting('code')">
                          {{ $t('DASHBOARD_ORDER_CODE') }}
                          <span v-if="queryString.sortBy === 'code'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div @click="setSorting('createdAt')">
                          {{ $t('DASHBOARD_ORDER_DATE') }}
                          <span v-if="queryString.sortBy === 'createdAt'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div @click="setSorting('consumerName')">
                          {{ $t('DASHBOARD_ORDER_CONSUMER_NAME') }}
                          <span v-if="queryString.sortBy === 'consumerName'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div @click="setSorting('consumerEmail')">
                          {{ $t('DASHBOARD_ORDER_CONSUMER_EMAIL') }}
                          <span v-if="queryString.sortBy === 'consumerEmail'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div @click="setSorting('consumerPhone')">
                          {{ $t('DASHBOARD_ORDER_CONSUMER_PHONE') }}
                          <span v-if="queryString.sortBy === 'consumerPhone'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div @click="setSorting('quantity')">
                          {{ $t('DASHBOARD_ORDER_QUANTITY') }}
                          <span v-if="queryString.sortBy === 'quantity'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="order in orders"
                      :key="order.id"
                      :class="{ 'is-order-deleting': isOrderDeleting(order.id), 'is-sent': order.status === 'SENT' }"
                    >
                      <td>
                        <input
                          type="checkbox"
                          :style="{ visibility: isSomeOrderDeleting ? 'hidden' : 'visible' }"
                          v-model="selectedOrders"
                          :value="order.id"
                          @change="checkSelectedOrders()"
                        />
                      </td>
                      <td>{{ order.code }}</td>
                      <td>{{ order.createdAt | date }}</td>
                      <td>{{ order.consumerName }}</td>
                      <td>{{ order.consumerEmail }}</td>
                      <td>{{ order.consumerPhone }}</td>
                      <td>{{ order.quantity }}</td>
                      <!-- Single Orders Actions -->
                      <td class="text-right action-column" v-if="selectedOrders.length <= 1">
                        <div v-if="!isOrderDeleting(order.id)">
                          <!-- action: delete -->
                          <button
                            class="Button Button__delete"
                            @click="setOrderDeleting(order.id, true)"
                            :title="$t('ACTION_DELETE')"
                            v-b-tooltip.hover
                          >
                            <Delete />
                          </button>
                          <!-- action: confirm -->
                          <button
                            v-if="order.product.enabled && order.status !== 'EXPIRED'"
                            class="Button Button__send"
                            :class="{ disabled: order.status === 'NEW' }"
                            v-b-modal="'modalConfirm' + order.id"
                            :title="$t('ACTION_CONFIRM')"
                            v-b-tooltip.hover
                          >
                            <Check />
                          </button>
                          <!-- action: view -->
                          <button
                            class="Button Button__view"
                            :class="{ disabled: order.status === 'NEW' }"
                            @click="downloadPdf(order.id, order.code)"
                            :title="$t('ACTION_VIEW')"
                            v-b-tooltip.hover
                          >
                            <Eye />
                          </button>
                          <!-- action: price -->
                          <button
                            v-if="order.product.enabled && order.status !== 'EXPIRED'"
                            class="Button Button__send"
                            :class="{ disabled: order.status === 'NEW' }"
                            v-b-modal="'modalPrice' + order.id"
                            :title="$t('ACTION_PRICE')"
                            v-b-tooltip.hover
                          >
                            <Calculator />
                          </button>
                        </div>
                        <div v-if="isOrderDeleting(order.id)">
                          <div class="confirm">
                            {{ $t('DELETE_CONFIRM_QUESTION') }}<br />
                            <button class="cursor-pointer" @click="deleteOrder(order.id)">{{ $t('YES') }}</button>
                            <button class="cursor-pointer" @click="setOrderDeleting(order.id, false)">
                              {{ $t('NO') }}
                            </button>
                          </div>
                        </div>
                        <!-- MODAL: confirm -->
                        <b-modal
                          :id="'modalConfirm' + order.id"
                          :ref="'modalConfirm' + order.id"
                          class="order-modal-price"
                          :title="$t('DASHBOARD_ORDER_CONFIRM_TITLE', { orderId: order.code })"
                          centered
                        >
                          <p class="text-left">
                            <strong>{{ $t('DASHBOARD_ORDER_CONSUMER_NAME') }}</strong
                            >: {{ order.consumerName }}<br />
                            <strong>{{ $t('DASHBOARD_ORDER_CONSUMER_EMAIL') }}</strong
                            >: {{ order.consumerEmail }}<br />
                            <strong>{{ $t('DASHBOARD_ORDER_CONSUMER_PHONE') }}</strong
                            >: {{ order.consumerPhone }}
                          </p>
                          <p class="text-left">
                            {{ $t('DASHBOARD_ORDER_CONFIRM_QUESTION') }}
                          </p>
                          <p class="text-left">
                            {{ $t('DASHBOARD_ORDER_CONFIRM_PAYOFF') }}
                          </p>
                          <template slot="modal-footer">
                            <div class="row">
                              <div class="col">
                                <button class="Button Button__edit Button--full-width" @click="confirmOrder(order.id)">
                                  <Check /> {{ $t('ACTION_CONFIRM') }}
                                </button>
                              </div>
                            </div>
                          </template>
                        </b-modal>
                        <!-- MODAL: calculate price -->
                        <ModalPriceDealer :order="order" />
                      </td>
                      <!-- Multiple Orders Actions -->
                      <td class="text-right action-column" v-if="selectedOrders.length > 1">
                        <button
                          class="Button Button__delete Button--full-width"
                          v-if="selectedOrders.includes(order.id)"
                          v-b-modal="'modalDeleteMultiple'"
                        >
                          <Delete /> {{ $t('ACTION_DELETE') }} ({{ selectedOrders.length }})
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- MODAL: delete multiple orders -->
                <b-modal
                  :id="'modalDeleteMultiple'"
                  :ref="'modalDeleteMultiple'"
                  class="order-modal-price"
                  :title="$t('DASHBOARD_ORDER_DELETE_MULTIPLE_TITLE', { orderCount: selectedOrders.length })"
                  centered
                >
                  <p class="text-left">
                    {{ $t('DASHBOARD_ORDER_DELETE_MULTIPLE_QUESTION') }}
                  </p>
                  <p class="text-left" v-html="printSelectedOrdersList()" />

                  <template slot="modal-footer">
                    <div class="row">
                      <div class="col">
                        <button class="Button Button__delete Button--full-width" @click="deleteSelectedOrders()">
                          <Delete /> {{ $t('ACTION_DELETE') }}
                        </button>
                      </div>
                    </div>
                  </template>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-show="!isChangingDashboard">
        <div class="col">
          <b-pagination
            v-if="ordersTotal > 0 && ordersTotal > queryString.limit"
            size="md"
            align="center"
            @input="setPagination"
            :total-rows="ordersTotal"
            v-model="queryString.page"
            :per-page="queryString.limit"
          />
        </div>
      </div>
    </div>
    <Loader v-if="isOrdersFetching || isConfiguratorFetching" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Plus from 'vue-material-design-icons/Plus.vue'
import ChevronUp from 'vue-material-design-icons/ChevronUp.vue'
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue'
import Delete from 'vue-material-design-icons/Delete.vue'
import Send from 'vue-material-design-icons/Send.vue'
import Eye from 'vue-material-design-icons/Eye.vue'
import Pencil from 'vue-material-design-icons/Pencil.vue'
import ContentCopy from 'vue-material-design-icons/ContentCopy.vue'
import CloudDownload from 'vue-material-design-icons/CloudDownload.vue'
import Magnify from 'vue-material-design-icons/Magnify.vue'
import Settings from 'vue-material-design-icons/Settings.vue'
import RestoreClock from 'vue-material-design-icons/RestoreClock.vue'
import Calculator from 'vue-material-design-icons/Calculator.vue'
import Lock from 'vue-material-design-icons/Lock.vue'
import Check from 'vue-material-design-icons/Check.vue'

import NoItemsFound from '@/components/NoItemsFound.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import Loader from '@/components/Loader.vue'
import ModalPriceDealer from '@/components/ModalPriceDealer.vue'
import FileDownload from '@/services/FileDownload'

export default {
  name: 'dashboard',
  components: {
    Plus,
    ChevronUp,
    ChevronDown,
    Delete,
    Send,
    Eye,
    Pencil,
    ContentCopy,
    CloudDownload,
    Magnify,
    Settings,
    RestoreClock,
    Calculator,
    Lock,
    Check,
    NoItemsFound,
    ErrorMessage,
    Loader,
    ModalPriceDealer
  },
  data() {
    return {
      filterCategory: 'code',
      orderCode: '',
      orderConsumerName: '',
      orderConsumerEmail: '',
      orderConsumerPhone: '',
      dateRange: null,
      fixedQueryParams: {
        type: 'B2C'
      },
      selectedOrders: [],
      selectedOrdersAll: false
    }
  },
  computed: {
    ...mapGetters([
      'isOrdersFetching',
      'isChangingDashboard',
      'isOrderDeleting',
      'isSomeOrderDeleting',
      'ordersDefaultParams',
      'orders',
      'ordersTotal',
      'ordersError',
      'loggedUser',
      'orderSelectedItem',
      'isConfiguratorFetching',
      'userCompany'
    ]),
    queryString() {
      const result = { ...this.ordersDefaultParams, ...this.$route.query }
      return {
        ...result,
        page: Number(result.page)
      }
    },
    dateRangeFilter() {
      const formatString = this.userCompany === 'USA' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
      let result = ''
      let dates
      if (this.queryString.sentAt) dates = this.queryString.sentAt.split(',')
      if (this.queryString.createdAt) dates = this.queryString.createdAt.split(',')
      if (dates && dates.length === 2) {
        result = moment(dates[0]).format(formatString) + ' - ' + moment(dates[1]).format(formatString)
      }
      return result
    }
  },
  methods: {
    filterSearch() {
      var query = this.cleanQueryString([
        'code',
        'createdAt',
        'sentAt',
        'consumerName',
        'consumerEmail',
        'consumerPhone'
      ])

      if (this.filterCategory === 'code' && this.orderCode) {
        query.code = this.orderCode
      } else if (this.filterCategory === 'date' && this.dateRange && this.dateRange.start && this.dateRange.end) {
        const dateFormat = 'YYYY-MM-DD'
        const start = moment(this.dateRange.start).format(dateFormat)
        const end = moment(this.dateRange.end).format(dateFormat)
        query.createdAt = `${start},${end}`
      } else if (this.filterCategory === 'consumerName' && this.orderConsumerName) {
        query.consumerName = this.orderConsumerName
      } else if (this.filterCategory === 'consumerEmail' && this.orderConsumerEmail) {
        query.consumerEmail = this.orderConsumerEmail
      } else if (this.filterCategory === 'consumerPhone' && this.orderConsumerPhone) {
        query.consumerPhone = this.orderConsumerPhone
      }

      this.$router.push({ query })
    },
    noFiltersSet() {
      return (
        !this.queryString.code &&
        !this.queryString.createdAt &&
        !this.queryString.sentAt &&
        !this.queryString.consumerName &&
        !this.queryString.consumerEmail &&
        !this.queryString.consumerPhone
      )
    },
    filterCancel(submit) {
      this.orderCode = ''
      this.orderConsumerName = ''
      this.orderConsumerEmail = ''
      this.orderConsumerPhone = ''
      this.dateRange = null
      this.filterCategory = 'code'

      if (submit) {
        this.$router.push({
          query: this.cleanQueryString([
            'code',
            'createdAt',
            'sentAt',
            'consumerName',
            'consumerEmail',
            'consumerPhone'
          ])
        })
      }
    },
    setFiltersFromQueryString() {
      this.filterCancel()

      if (this.queryString.code) {
        this.orderCode = this.queryString.code
      } else if (this.queryString.createdAt || this.queryString.sentAt) {
        let dates
        if (this.queryString.createdAt) {
          dates = this.queryString.createdAt.split(',')
        } else if (this.queryString.sentAt) {
          dates = this.queryString.sentAt.split(',')
        }
        this.filterCategory = 'date'
        this.dateRange = {
          start: moment(dates[0]).toDate(),
          end: moment(dates[1]).toDate()
        }
      } else if (this.queryString.consumerName) {
        this.filterCategory = 'consumerName'
        this.orderConsumerName = this.queryString.consumerName
      } else if (this.queryString.consumerEmail) {
        this.filterCategory = 'consumerEmail'
        this.orderConsumerEmail = this.queryString.consumerEmail
      } else if (this.queryString.consumerPhone) {
        this.filterCategory = 'consumerPhone'
        this.orderConsumerPhone = this.queryString.consumerPhone
      }
    },
    setQueryString(value) {
      const query = {
        ...this.$route.query,
        ...value
      }
      this.$router.push({ query })
    },
    cleanQueryString(keys) {
      return Object.keys(this.$route.query).reduce((res, e) => {
        if (!keys.includes(e)) {
          res[e] = this.$route.query[e]
        }
        return res
      }, {})
    },
    setSorting(field) {
      let dir = this.queryString.sortBy === field ? (this.queryString.sortDir === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
      this.setQueryString({ sortBy: field, sortDir: dir })
    },
    setPagination(page) {
      this.setQueryString({ page })
    },
    getOrdersList(params) {
      this.$store.dispatch('ORDERS_GET_LIST', { ...params, ...this.fixedQueryParams })
    },
    gotoOrderPage(id) {
      this.$router.push({ name: 'configurator', params: { order: id } })
    },
    setOrderDeleting(id, value) {
      this.selectedOrders = []
      this.selectedOrdersAll = false
      this.$store.dispatch('ORDERS_SET_IS_DELETING', { id, value })
    },
    deleteOrder(id) {
      this.$store.dispatch('ORDERS_DELETE_FORCE', id).then(() => {
        this.selectedOrders = []
        this.selectedOrdersAll = false
      })
    },
    downloadPdf(id, orderCode) {
      this.$store.dispatch('ORDERS_GET_PDF', id).then(data => {
        FileDownload(orderCode + '.pdf', 'application/pdf', data)
      })
    },
    onModalPriceShown(order) {
      this.$store.dispatch('CONFIGURATOR_GET_SETTINGS').then(() => {
        this.$store.dispatch('ORDERS_GET_ITEM', order.id).then(() => {
          if (this.orderSelectedItem && this.orderSelectedItem.configuration) {
            this.$store.dispatch('CONFIGURATOR_SELECT_MODEL', this.orderSelectedItem.configuration.sku).then(() => {
              this.$store.dispatch('CONFIGURATOR_IMPORT', { configuration: this.orderSelectedItem.configuration })
            })
          } else {
            this.$store.commit('ORDERS_ERROR')
            this.$store.commit('CONFIGURATOR_IS_FETCHING', false)
          }
        })
      })
    },
    toggleSelectionOrders() {
      if (this.selectedOrdersAll === true) {
        this.selectedOrders = [...this.orders.map(x => x.id)]
      } else {
        this.selectedOrders = []
      }
    },
    checkSelectedOrders() {
      if (this.selectedOrders.length === this.orders.length) {
        this.selectedOrdersAll = true
      } else {
        this.selectedOrdersAll = false
      }
    },
    printSelectedOrdersList() {
      return this.orders
        .filter(x => this.selectedOrders && this.selectedOrders.length > 0 && this.selectedOrders.includes(x.id))
        .map(x => '<div class="pb-2">' + x.code + '</div>')
        .join('')
    },
    deleteSelectedOrders() {
      this.deleteOrder(this.selectedOrders.join(','))
      this.$refs.modalDeleteMultiple.hide()
    },
    confirmOrder(id) {
      this.$store.dispatch('ORDERS_CONFIRM_B2C', id)
    }
  },
  watch: {
    $route() {
      this.setFiltersFromQueryString()
      this.getOrdersList(this.queryString)
    }
  },
  created() {
    this.setFiltersFromQueryString()
    this.getOrdersList(this.queryString)
  },
  beforeRouteLeave(to, from, next) {
    if (to && to.name === 'dashboard') {
      this.$store.dispatch('ORDERS_IS_CHANGING_DASHBOARD', true)
    }
    next()
  }
}
</script>

<style scoped lang="scss">
.B2CDashboard {
  padding-top: 30px;

  .Filters {
    color: $c-white;
    background: $c-black;
    &__form {
      padding: 25px 15px;
      h2 {
        font-size: 24px;
        margin: 0 0 15px 0;
      }
    }
    &__filter-type-btn {
      border: none;
      border-left: 1px solid $c-blue;
      border-right: 1px solid $c-blue;
      font-size: 16px;
      cursor: pointer;
      border-radius: 0;
      background: $c-white;
      color: $c-blue;
      text-align: center;
      width: 100%;
      // width: 150px;
      // &.larger {
      //   width: 165px;
      // }
      padding: 5px 0;

      &.selected {
        background: $c-blue;
        color: $c-white;
      }
    }
    .left-separator {
      border-left: 1px solid $c-grey2;
    }
    .form-control {
      // width: 32% !important;
      display: inline-block;
      border-radius: 0;
      padding: 5px;
    }
    .Filters-submit {
      width: 100%;
      max-width: 100%;
      padding: 5px !important;
    }
    .sent-order-text {
      font-size: 14px;
    }
    &__set {
      color: $c-grey;
      .no-filter {
        font-style: italic;
      }
      .reset-filter {
        color: $c-error;
        opacity: 0.8;
      }
    }
  }
  .is-sent,
  .is-closed {
    background: $c-grey4 !important;
  }
  .is-order-deleting {
    background: rgba($c-black, 0.7) !important;
    .confirm {
      color: $c-white !important;

      button {
        font-size: 18px;
        color: $c-white;
        padding: 0 10px;
        margin-left: 15px;
        background: transparent;
        border: 1px solid $c-white;
      }
    }
  }

  .Table {
    margin-top: 15px;
    &__row {
      position: relative;
    }

    &__delete-row {
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
    }

    .action-column {
      width: 45%;
      @include only-xl() {
        width: auto;
      }
    }
  }

  .order-modal-price-body {
    min-height: 150px;
  }

  .modalSend__payoff {
    text-align: left;
    margin: 30px 0 0 0;
    font-size: 14px;
  }
}
</style>
