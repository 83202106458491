<template>
  <div class="Dashboard">
    <div class="container-fluid">
      <div class="row">
        <div class="col-3">
          <h1 class="Page__title">{{ $t('DASHBOARD_TITLE') }}</h1>
        </div>
        <div class="col-9">
          <button v-if="!isAdmin" class="Button Button__create" @click="createOrder()">
            <Plus /> {{ $t('DASHBOARD_NEW_ORDER') }}
          </button>
        </div>
      </div>
      <div class="Filters row">
        <div class="col">
          <div class="Filters__form">
            <div class="row">
              <div class="col-9">
                <div class="row">
                  <div class="col">
                    <h2><Magnify /> {{ $t('DASHBOARD_FILTER_TITLE_SEARCH') }}</h2>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <form @submit.prevent="filterSearch()">
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div :class="{ 'col-3': isAdmin, 'col-4': !isAdmin }">
                              <button
                                class="Filters__filter-type-btn cursor-pointer"
                                :class="{ selected: filterCategory === 'code' }"
                                type="button"
                                @click="filterCategory = 'code'"
                              >
                                {{ $t('DASHBOARD_FILTER_BY_ORDER') }}
                              </button>
                            </div>
                            <div :class="{ 'col-3': isAdmin, 'col-4': !isAdmin }" v-if="isAdmin">
                              <button
                                class="Filters__filter-type-btn cursor-pointer"
                                :class="{ selected: filterCategory === 'userName' }"
                                type="button"
                                @click="filterCategory = 'userName'"
                              >
                                {{ $t('DASHBOARD_FILTER_BY_CUSTOMER') }}
                              </button>
                            </div>
                            <div :class="{ 'col-3': isAdmin, 'col-4': !isAdmin }">
                              <button
                                class="Filters__filter-type-btn mr-2 mr-xl-4 cursor-pointer"
                                :class="{ selected: filterCategory === 'date' }"
                                type="button"
                                @click="filterCategory = 'date'"
                              >
                                {{ $t('DASHBOARD_FILTER_BY_DATE') }}
                              </button>
                            </div>
                            <div :class="{ 'col-3': isAdmin, 'col-4': !isAdmin }">
                              <button
                                class="Filters__filter-type-btn mr-2 mr-xl-4 cursor-pointer"
                                :class="{ selected: filterCategory === 'reference' }"
                                type="button"
                                @click="filterCategory = 'reference'"
                              >
                                {{ $t('DASHBOARD_FILTER_BY_REF') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <div class="row">
                            <div class="col-10">
                              <input
                                type="text"
                                class="form-control"
                                v-model="orderCode"
                                v-if="filterCategory === 'code'"
                                :placeholder="$t('DASHBOARD_FILTER_PLACEHOLDER_ORDER')"
                              />

                              <v-date-picker
                                v-if="filterCategory === 'date'"
                                v-model="dateRange"
                                mode="range"
                                popover-visibility="focus"
                                tint-color="#001489"
                                :is-double-paned="true"
                                :is-required="false"
                                :input-props="{
                                  class: 'form-control',
                                  placeholder: $t('DASHBOARD_FILTER_PLACEHOLDER_DATE'),
                                  readonly: true
                                }"
                              >
                              </v-date-picker>

                              <input
                                type="text"
                                class="form-control"
                                v-model="userName"
                                v-if="filterCategory === 'userName'"
                                :placeholder="$t('DASHBOARD_FILTER_PLACEHOLDER_CUSTOMER')"
                              />

                              <input
                                type="text"
                                class="form-control"
                                v-model="orderRef"
                                v-if="filterCategory === 'reference'"
                                :placeholder="$t('DASHBOARD_FILTER_PLACEHOLDER_REF')"
                              />
                            </div>
                            <div class="col-2">
                              <button class="Button Button__create Filters-submit cursor-pointer" type="submit">
                                {{ $t('ACTION_SEARCH') }}
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="row">
                  <div class="col">
                    <div class="mt-4 Filters__set">
                      <div class="no-filter" v-if="noFiltersSet()">
                        {{ $t('DASHBOARD_FILTER_RECAP_NO_FILTER') }}
                      </div>
                      <div v-else>
                        <span v-if="queryString.code"
                          >{{ $t('DASHBOARD_FILTER_RECAP_PREFIX') }} {{ $t('DASHBOARD_FILTER_BY_ORDER') }}:
                          {{ queryString.code }}</span
                        >
                        <span v-if="queryString.userName"
                          >{{ $t('DASHBOARD_FILTER_RECAP_PREFIX') }} {{ $t('DASHBOARD_FILTER_BY_CUSTOMER') }}:
                          {{ queryString.userName }}</span
                        >
                        <span v-if="queryString.createdAt || queryString.sentAt"
                          >{{ $t('DASHBOARD_FILTER_RECAP_PREFIX') }} {{ $t('DASHBOARD_FILTER_BY_DATE') }}:
                          {{ dateRangeFilter }}</span
                        >
                        <span v-if="queryString.reference"
                          >{{ $t('DASHBOARD_FILTER_RECAP_PREFIX') }} {{ $t('DASHBOARD_FILTER_BY_REF') }}:
                          {{ queryString.reference }}</span
                        >
                        <span class="reset-filter cursor-pointer pl-3" @click="filterCancel(true)"> <Delete /></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-3 left-separator">
                <div class="row">
                  <div class="col">
                    <h2><Settings /> {{ $t('DASHBOARD_FILTER_TITLE_SETTINGS') }}</h2>
                  </div>
                </div>
                <!-- ADMIN: show closed orders -->
                <div class="row" v-if="isAdmin">
                  <div class="col-12">
                    <toggle-button
                      @change="onChangeClosedOrderToggle"
                      :value="queryString.status && queryString.status.includes('CLOSED')"
                      :color="{ checked: '#fff', unchecked: '#808080' }"
                      :switchColor="{ checked: '#001489', unchecked: '#001489' }"
                      :width="70"
                      :height="30"
                    />
                    <span class="ml-xl-2 sent-order-text pl-3">{{ $t('DASHBOARD_FILTER_SHOW_CLOSED') }}</span>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group form-check">
                              <input
                                type="checkbox"
                                value="ITA"
                                v-model="queryString.company"
                                @change="onChangeCompanyFilter"
                                class="form-check-input"
                                id="checkITA"
                              />
                              <label class="form-check-label" for="checkITA">Sparco Italia</label>
                            </div>
                          </div>
                          <div class="col-12">
                            <div class="form-group form-check">
                              <input
                                type="checkbox"
                                value="USA"
                                v-model="queryString.company"
                                @change="onChangeCompanyFilter"
                                class="form-check-input"
                                id="checkUSA"
                              />
                              <label class="form-check-label" for="checkUSA">Sparco USA</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- DEALER: show sent orders -->
                <div class="row" v-if="!isAdmin">
                  <div class="col">
                    <toggle-button
                      @change="onChangeSentOrderToggle"
                      :value="queryString.status && queryString.status.includes('SENT')"
                      :color="{ checked: '#fff', unchecked: '#808080' }"
                      :switchColor="{ checked: '#001489', unchecked: '#001489' }"
                      :width="70"
                      :height="30"
                    />
                    <span class="sent-order-text pl-3">{{ $t('DASHBOARD_FILTER_SHOW_SENT') }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="Table">
            <ErrorMessage v-if="ordersError && !isOrdersFetching" />
            <div v-if="!ordersError">
              <NoItemsFound v-if="ordersTotal === 0 && !isOrdersFetching" />
              <div v-if="ordersTotal > 0" v-show="!isChangingDashboard">
                <table class="table table-striped table-bordered">
                  <thead>
                    <tr>
                      <th scope="col">
                        <div @click="setSorting('code')">
                          {{ $t('DASHBOARD_ORDER_CODE') }}
                          <span v-if="queryString.sortBy === 'code'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col" v-if="isAdmin">
                        <div @click="setSorting('userName')">
                          {{ $t('DASHBOARD_CUSTOMER_NAME') }}
                          <span v-if="queryString.sortBy === 'userName'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col" v-if="!isAdmin">
                        <div @click="setSorting('createdAt')">
                          {{ $t('DASHBOARD_ORDER_DATE') }}
                          <span v-if="queryString.sortBy === 'createdAt'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col" v-if="!isAdmin">
                        <div @click="setSorting('status')">
                          {{ $t('DASHBOARD_ORDER_STATUS') }}
                          <span v-if="queryString.sortBy === 'status'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col" v-if="isAdmin">
                        <div @click="setSorting('createdAt')">
                          {{ $t('DASHBOARD_ORDER_CREATED') }}
                          <span v-if="queryString.sortBy === 'createdAt'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col" v-if="isAdmin">
                        <div @click="setSorting('sentAt')">
                          {{ $t('DASHBOARD_ORDER_SENT') }}
                          <span v-if="queryString.sortBy === 'sentAt'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div @click="setSorting('quantity')">
                          {{ $t('DASHBOARD_ORDER_QUANTITY') }}
                          <span v-if="queryString.sortBy === 'quantity'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col">
                        <div @click="setSorting('reference')">
                          {{ $t('DASHBOARD_ORDER_REFERENCE') }}
                          <span v-if="queryString.sortBy === 'reference'">
                            <ChevronUp v-if="queryString.sortDir === 'ASC'" />
                            <ChevronDown v-if="queryString.sortDir === 'DESC'" />
                          </span>
                        </div>
                      </th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="order in orders"
                      :key="order.id"
                      :class="{
                        'is-order-deleting': isOrderDeleting(order.id),
                        'is-sent': !isAdmin && order.status === 'SENT',
                        'is-closed': isAdmin && order.status === 'CLOSED'
                      }"
                    >
                      <td>{{ order.code }}</td>
                      <td v-if="isAdmin">{{ order.userName }}</td>
                      <td>{{ order.createdAt | date }}</td>
                      <td v-if="isAdmin">{{ order.sentAt | date }}</td>
                      <td v-if="!isAdmin">
                        <span v-if="order.product.enabled && order.status !== 'EXPIRED'">
                          {{ $t('DASHBOARD_ORDER_STATUS_' + order.status.replace('-', '_')) }}
                        </span>
                        <span v-if="!order.product.enabled || order.status === 'EXPIRED'">
                          {{ $t('OUT_OF_CATALOG') }}
                        </span>
                      </td>
                      <td>{{ order.quantity }}</td>
                      <td>{{ order.reference | maxLength(25) }}</td>
                      <td class="text-right action-column">
                        <div v-if="!isOrderDeleting(order.id)">
                          <!-- action: close -->
                          <button
                            class="Button Button__edit"
                            v-if="
                              isAdmin &&
                                order.product.enabled &&
                                order.status !== 'EXPIRED' &&
                                order.status !== 'CLOSED'
                            "
                            v-b-modal="'modalClose' + order.id"
                            :title="$t('ACTION_CLOSE')"
                            v-b-tooltip.hover
                          >
                            <Lock />
                          </button>
                          <!-- action consumer info -->
                          <button
                            class="Button Button__send mr-4"
                            v-if="!isAdmin && order.converted"
                            v-b-modal="'modalConsumerInfo' + order.id"
                            :title="$t('ACTION_CONSUMER_INFO')"
                            v-b-tooltip.hover
                          >
                            <AccountCardDetails />
                          </button>
                          <!-- action: delete -->
                          <button
                            class="Button Button__delete"
                            v-if="(isAdmin || order.status !== 'SENT') && order.status !== 'CLOSED'"
                            @click="setOrderDeleting(order.id, true)"
                            :title="$t('ACTION_DELETE')"
                            v-b-tooltip.hover
                          >
                            <Delete />
                          </button>
                          <!-- action: rollback -->
                          <button
                            class="Button Button__send"
                            v-if="
                              isAdmin &&
                                order.product.enabled &&
                                order.status !== 'EXPIRED' &&
                                order.status !== 'CLOSED'
                            "
                            v-b-modal="'modalReset' + order.id"
                            :title="$t('ACTION_ROLLBACK')"
                            v-b-tooltip.hover
                          >
                            <RestoreClock />
                          </button>
                          <!-- action: send -->
                          <button
                            class="Button Button__send"
                            :class="{ disabled: order.status === 'NEW' }"
                            v-if="
                              !isAdmin && order.product.enabled && order.status !== 'EXPIRED' && order.status !== 'SENT'
                            "
                            v-b-modal="'modalSend' + order.id"
                            :title="$t('ACTION_SEND')"
                            v-b-tooltip.hover
                          >
                            <Send />
                          </button>
                          <!-- action: export -->
                          <button
                            class="Button Button__view"
                            :class="{ disabled: order.status === 'NEW' }"
                            v-if="isAdmin"
                            @click="downloadZip(order.id)"
                            :title="$t('ACTION_EXPORT')"
                            v-b-tooltip.hover
                          >
                            <CloudDownload />
                          </button>
                          <!-- action: view -->
                          <button
                            class="Button Button__view"
                            :class="{ disabled: order.status === 'NEW' }"
                            @click="downloadPdf(order.id, order.code)"
                            :title="$t('ACTION_VIEW')"
                            v-b-tooltip.hover
                          >
                            <Eye />
                          </button>
                          <!-- action: price -->
                          <button
                            class="Button Button__send"
                            v-if="
                              (isAdmin || order.status !== 'SENT') &&
                                order.product.enabled &&
                                order.status !== 'EXPIRED' &&
                                order.status !== 'CLOSED'
                            "
                            :class="{ disabled: order.status === 'NEW' }"
                            v-b-modal="'modalPrice' + order.id"
                            :title="$t('ACTION_PRICE')"
                            v-b-tooltip.hover
                          >
                            <Calculator />
                          </button>
                          <!-- action: clone -->
                          <button
                            class="Button Button__view"
                            v-if="
                              !isAdmin &&
                                order.product.enabled &&
                                order.status !== 'EXPIRED' &&
                                (order.status === 'SENT' || order.status === 'IN-PROGRESS')
                            "
                            @click="cloneOrder(order.id)"
                            :title="$t('ACTION_CLONE')"
                            v-b-tooltip.hover
                          >
                            <ContentCopy />
                          </button>
                          <!-- action: edit -->
                          <button
                            class="Button Button__edit"
                            v-if="
                              (isAdmin || order.status !== 'SENT') &&
                                order.product.enabled &&
                                order.status !== 'EXPIRED' &&
                                order.status !== 'CLOSED'
                            "
                            @click="gotoOrderPage(order.id)"
                            :title="$t('ACTION_EDIT')"
                            v-b-tooltip.hover
                          >
                            <Pencil />
                          </button>
                        </div>
                        <div v-if="isOrderDeleting(order.id)">
                          <div class="confirm">
                            {{ $t('DELETE_CONFIRM_QUESTION') }}
                            <button class="cursor-pointer" @click="deleteOrder(order.id)">{{ $t('YES') }}</button>
                            <button class="cursor-pointer" @click="setOrderDeleting(order.id, false)">
                              {{ $t('NO') }}
                            </button>
                          </div>
                        </div>
                        <!-- MODAL: confirm close -->
                        <b-modal
                          v-if="isAdmin"
                          :id="'modalClose' + order.id"
                          :ref="'modalClose' + order.id"
                          :title="order.code"
                          centered
                        >
                          <p class="text-left">
                            {{ $t('DASHBOARD_ORDER_CLOSE_CONFIRM_QUESTION') }}
                          </p>
                          <template slot="modal-footer">
                            <button class="Button Button__edit" @click="closeOrder(order.id)">
                              <Lock /> {{ $t('ACTION_CLOSE') }}
                            </button>
                          </template>
                        </b-modal>
                        <!-- MODAL: confirm send -->
                        <b-modal
                          v-if="!isAdmin"
                          :id="'modalSend' + order.id"
                          :ref="'modalSend' + order.id"
                          :title="order.code"
                          centered
                        >
                          <p class="text-left">
                            {{ $t('DASHBOARD_ORDER_SEND_CONFIRM_QUESTION') }}
                          </p>

                          <p class="modalSend__payoff">
                            {{ $t('DASHBOARD_ORDER_SEND_CONFIRM_PAYOFF_1') }}
                            <br />
                            {{ $t('DASHBOARD_ORDER_SEND_CONFIRM_PAYOFF_2') }}
                          </p>
                          <template slot="modal-footer">
                            <button class="Button Button__edit" style="max-width: none;" @click="sendOrder(order.id)">
                              <Send /> {{ $t('ACTION_SEND') }}
                            </button>
                          </template>
                        </b-modal>
                        <!-- MODAL: rollback order -->
                        <b-modal
                          v-if="isAdmin"
                          :id="'modalReset' + order.id"
                          :ref="'modalReset' + order.id"
                          :title="order.code"
                          centered
                        >
                          <p class="text-left">
                            {{ $t('DASHBOARD_ORDER_ROLLBACK_CONFIRM_QUESTION', { dealerName: order.userName }) }}
                          </p>
                          <template slot="modal-footer">
                            <button class="Button Button__edit" @click="rollbackOrder(order.id)">
                              <RestoreClock /> {{ $t('ACTION_ROLLBACK') }}
                            </button>
                          </template>
                        </b-modal>
                        <!-- MODAL: consumer info -->
                        <b-modal
                          v-if="!isAdmin"
                          :id="'modalConsumerInfo' + order.id"
                          :ref="'modalConsumerInfo' + order.id"
                          :title="order.code"
                          hide-footer
                          centered
                        >
                          <p class="text-left">
                            <strong>{{ $t('DASHBOARD_ORDER_CONSUMER_NAME') }}</strong
                            >: {{ order.consumerName }}<br />
                          </p>
                          <p class="text-left">
                            <strong>{{ $t('DASHBOARD_ORDER_CONSUMER_EMAIL') }}</strong
                            >: {{ order.consumerEmail }}<br />
                          </p>
                          <p class="text-left">
                            <strong>{{ $t('DASHBOARD_ORDER_CONSUMER_PHONE') }}</strong
                            >: {{ order.consumerPhone }}
                          </p>
                        </b-modal>
                        <!-- MODAL: calculate price -->
                        <ModalPriceDealer :order="order" />
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- MODAL: download zip -->
                <b-modal
                  v-if="isAdmin"
                  id="modalDownloadZip"
                  ref="modalDownloadZip"
                  title=""
                  hide-footer
                  centered
                  @hidden="onModalZipHidden()"
                >
                  <div class="text-center">
                    <p class="download-s3-p">{{ $t('DASHBOARD_DOWNLOAD_S3_MODAL') }}</p>
                    <p>
                      <a :href="downloadZipUrl" download class="download-s3-btn"><CloudDownload /></a>
                    </p>
                  </div>
                </b-modal>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-show="!isChangingDashboard">
        <div class="col">
          <b-pagination
            v-if="ordersTotal > 0 && ordersTotal > queryString.limit"
            size="md"
            align="center"
            @input="setPagination"
            :total-rows="ordersTotal"
            v-model="queryString.page"
            :per-page="queryString.limit"
          />
        </div>
      </div>
    </div>
    <Loader v-if="isOrdersFetching || isConfiguratorFetching" />
    <b-modal
      class="modalWarn"
      id="new-catalog"
      ref="newCatalogModal"
      :title="$t('CFG_TEXT_WARNING_TITLE')"
      centered
      hide-footer
      @shown="() => $store.commit('CONFIGURATOR_CATALOG_UPDATE_WARNING', false)"
    >
      <p>
        {{ $t('CATALOG_CHANGE_WARNING') }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import Plus from 'vue-material-design-icons/Plus.vue'
import ChevronUp from 'vue-material-design-icons/ChevronUp.vue'
import ChevronDown from 'vue-material-design-icons/ChevronDown.vue'
import Delete from 'vue-material-design-icons/Delete.vue'
import Send from 'vue-material-design-icons/Send.vue'
import Eye from 'vue-material-design-icons/Eye.vue'
import Pencil from 'vue-material-design-icons/Pencil.vue'
import ContentCopy from 'vue-material-design-icons/ContentCopy.vue'
import CloudDownload from 'vue-material-design-icons/CloudDownload.vue'
import Magnify from 'vue-material-design-icons/Magnify.vue'
import Settings from 'vue-material-design-icons/Settings.vue'
import RestoreClock from 'vue-material-design-icons/RestoreClock.vue'
import Calculator from 'vue-material-design-icons/Calculator.vue'
import Lock from 'vue-material-design-icons/Lock.vue'
import AccountCardDetails from 'vue-material-design-icons/AccountCardDetails.vue'

import NoItemsFound from '@/components/NoItemsFound.vue'
import ErrorMessage from '@/components/ErrorMessage.vue'
import Loader from '@/components/Loader.vue'
import ModalPriceDealer from '@/components/ModalPriceDealer.vue'
import FileDownload from '@/services/FileDownload'

export default {
  name: 'dashboard',
  components: {
    Plus,
    ChevronUp,
    ChevronDown,
    Delete,
    Send,
    Eye,
    Pencil,
    ContentCopy,
    CloudDownload,
    Magnify,
    Settings,
    RestoreClock,
    Calculator,
    Lock,
    AccountCardDetails,
    NoItemsFound,
    ErrorMessage,
    Loader,
    ModalPriceDealer
  },
  data() {
    return {
      filterCategory: 'code',
      orderCode: '',
      orderRef: '',
      userName: '',
      dateRange: null,
      fixedQueryParams: {
        type: 'B2B'
      },
      downloadZipUrl: ''
    }
  },
  computed: {
    ...mapGetters([
      'showModalWarning',
      'isAdmin',
      'isOrdersFetching',
      'isChangingDashboard',
      'isOrderDeleting',
      'isSomeOrderDeleting',
      'ordersDefaultParams',
      'orders',
      'ordersTotal',
      'ordersError',
      'loggedUser',
      'orderSelectedItem',
      'isConfiguratorFetching',
      'userCompany'
    ]),
    queryString() {
      const result = { ...this.ordersDefaultParams, ...this.$route.query }
      return {
        ...result,
        page: Number(result.page)
      }
    },
    dateRangeFilter() {
      const formatString = this.userCompany === 'USA' ? 'MM/DD/YYYY' : 'DD/MM/YYYY'
      let result = ''
      let dates
      if (this.queryString.sentAt) dates = this.queryString.sentAt.split(',')
      if (this.queryString.createdAt) dates = this.queryString.createdAt.split(',')
      if (dates && dates.length === 2) {
        result = moment(dates[0]).format(formatString) + ' - ' + moment(dates[1]).format(formatString)
      }
      return result
    }
  },
  mounted() {
    if (this.showModalWarning) {
      this.$refs.newCatalogModal.show()
    }
  },
  methods: {
    filterSearch() {
      var query = this.cleanQueryString(['code', 'createdAt', 'sentAt', 'userName', 'reference'])

      if (this.filterCategory === 'code' && this.orderCode) {
        query.code = this.orderCode
      } else if (this.filterCategory === 'userName' && this.userName) {
        query.userName = this.userName
      } else if (this.filterCategory === 'date' && this.dateRange && this.dateRange.start && this.dateRange.end) {
        const dateField = this.isAdmin ? 'sentAt' : 'createdAt'
        const dateFormat = 'YYYY-MM-DD'
        const start = moment(this.dateRange.start).format(dateFormat)
        const end = moment(this.dateRange.end).format(dateFormat)
        query[dateField] = `${start},${end}`
      } else if (this.filterCategory === 'reference' && this.orderRef) {
        query.reference = this.orderRef
      }

      this.$router.push({ query })
    },
    noFiltersSet() {
      return (
        !this.queryString.code &&
        !this.queryString.userName &&
        !this.queryString.createdAt &&
        !this.queryString.sentAt &&
        !this.queryString.reference
      )
    },
    filterCancel(submit) {
      this.orderCode = ''
      this.orderRef = ''
      this.userName = ''
      this.dateRange = null
      this.filterCategory = 'code'

      if (submit) {
        this.$router.push({ query: this.cleanQueryString(['code', 'createdAt', 'sentAt', 'userName', 'reference']) })
      }
    },
    setFiltersFromQueryString() {
      this.filterCancel()

      if (this.queryString.code) {
        this.orderCode = this.queryString.code
      } else if (this.queryString.userName) {
        this.filterCategory = 'userName'
        this.userName = this.queryString.userName
      } else if (this.queryString.createdAt || this.queryString.sentAt) {
        let dates
        if (this.queryString.createdAt) {
          dates = this.queryString.createdAt.split(',')
        } else if (this.queryString.sentAt) {
          dates = this.queryString.sentAt.split(',')
        }
        this.filterCategory = 'date'
        this.dateRange = {
          start: moment(dates[0]).toDate(),
          end: moment(dates[1]).toDate()
        }
      } else if (this.queryString.reference) {
        this.filterCategory = 'reference'
        this.orderRef = this.queryString.reference
      }

      if (this.queryString && typeof this.queryString.company === 'string') {
        this.queryString.company = this.queryString.company.split(',')
      }
    },
    onChangeClosedOrderToggle(data) {
      if (data.value === true) {
        this.setQueryString({
          status: Array.isArray(this.queryString.status)
            ? [...this.queryString.status, 'CLOSED'].join(',')
            : this.queryString.status
        })
      } else {
        this.$router.push({ query: this.cleanQueryString(['status']) })
      }
    },
    onChangeSentOrderToggle(data) {
      if (data.value === true) {
        this.setQueryString({
          status: Array.isArray(this.queryString.status)
            ? [...this.queryString.status, 'SENT'].join(',')
            : this.queryString.status
        })
      } else {
        this.$router.push({ query: this.cleanQueryString(['status']) })
      }
    },
    onChangeCompanyFilter() {
      const nullValue = 'NULL'
      let companyFilter = this.queryString.company.filter(x => !!x)
      if (companyFilter.length > 1 && companyFilter.includes(nullValue)) {
        companyFilter = companyFilter.filter(x => x !== nullValue)
      }
      this.setQueryString({
        company: companyFilter.length > 0 ? companyFilter.join(',') : nullValue
      })
    },
    setQueryString(value) {
      const query = {
        ...this.$route.query,
        ...value
      }
      this.$router.push({ query })
    },
    cleanQueryString(keys) {
      return Object.keys(this.$route.query).reduce((res, e) => {
        if (!keys.includes(e)) {
          res[e] = this.$route.query[e]
        }
        return res
      }, {})
    },
    setSorting(field) {
      let dir = this.queryString.sortBy === field ? (this.queryString.sortDir === 'ASC' ? 'DESC' : 'ASC') : 'ASC'
      this.setQueryString({ sortBy: field, sortDir: dir })
    },
    setPagination(page) {
      this.setQueryString({ page })
    },
    getOrdersList(params) {
      this.$store.dispatch('ORDERS_GET_LIST', { ...params, ...this.fixedQueryParams })
    },
    gotoOrderPage(id) {
      this.$router.push({ name: 'configurator', params: { order: id } })
    },
    createOrder() {
      this.$store.dispatch('ORDERS_CREATE').then(order => {
        if (order && order.id) {
          this.gotoOrderPage(order.id)
        }
      })
    },
    setOrderDeleting(id, value) {
      this.$store.dispatch('ORDERS_SET_IS_DELETING', { id, value })
    },
    deleteOrder(id) {
      this.$store.dispatch('ORDERS_DELETE', id)
    },
    sendOrder(id) {
      this.$refs['modalSend' + id].pop().hide()
      this.$store.dispatch('ORDERS_SEND', id)
    },
    closeOrder(id) {
      this.$refs['modalClose' + id].pop().hide()
      this.$store.dispatch('ORDERS_CLOSE', id)
    },
    rollbackOrder(id) {
      this.$refs['modalReset' + id].pop().hide()
      this.$store.dispatch('ORDERS_ROLLBACK', id)
    },
    cloneOrder(id) {
      this.$store.dispatch('ORDERS_CLONE', id).then(data => {
        if (data && data.id) {
          this.gotoOrderPage(data.id)
        }
      })
    },
    downloadPdf(id, orderCode) {
      this.$store.dispatch('ORDERS_GET_PDF', id).then(data => {
        FileDownload(orderCode + '.pdf', 'application/pdf', data)
      })
    },
    downloadZip(id) {
      this.$store.dispatch('ORDERS_GET_ZIP', id).then(data => {
        if (data && data.url) {
          this.downloadZipUrl = data.url
          this.$refs.modalDownloadZip.show()
        }
      })
    },
    onModalZipHidden() {
      this.downloadZipUrl = ''
    }
  },
  watch: {
    $route() {
      this.setFiltersFromQueryString()
      this.getOrdersList(this.queryString)
    }
  },
  created() {
    this.setFiltersFromQueryString()
    this.getOrdersList(this.queryString)
  },
  beforeRouteLeave(to, from, next) {
    if (to && to.name === 'configurator' && from && from.query) {
      this.$store.dispatch('ORDERS_SET_LAST_QUERY', from.query)
    } else if (to && to.name === 'B2C_dashboard') {
      this.$store.dispatch('ORDERS_IS_CHANGING_DASHBOARD', true)
    }
    next()
  }
}
</script>

<style scoped lang="scss">
.Dashboard {
  padding-top: 30px;

  .Filters {
    color: $c-white;
    background: $c-black;
    &__form {
      padding: 25px 15px;
      h2 {
        font-size: 24px;
        margin: 0 0 15px 0;
      }
    }
    &__filter-type-btn {
      border: none;
      border-left: 1px solid $c-blue;
      border-right: 1px solid $c-blue;
      font-size: 16px;
      cursor: pointer;
      border-radius: 0;
      background: $c-white;
      color: $c-blue;
      text-align: center;
      width: 100%;
      // width: 150px;
      // &.larger {
      //   width: 165px;
      // }
      padding: 5px 0;

      &.selected {
        background: $c-blue;
        color: $c-white;
      }
    }
    .left-separator {
      border-left: 1px solid $c-grey2;
    }
    .form-control {
      // width: 32% !important;
      display: inline-block;
      border-radius: 0;
      padding: 5px;
    }
    .Filters-submit {
      width: 100%;
      max-width: 100%;
      padding: 5px !important;
    }
    .sent-order-text {
      font-size: 14px;
    }
    &__set {
      color: $c-grey;
      .no-filter {
        font-style: italic;
      }
      .reset-filter {
        color: $c-error;
        opacity: 0.8;
      }
    }
  }
  .is-sent,
  .is-closed {
    background: $c-grey4 !important;
  }
  .is-order-deleting {
    background: rgba($c-black, 0.7) !important;
    .confirm {
      color: $c-white !important;

      button {
        font-size: 18px;
        color: $c-white;
        padding: 0 10px;
        margin-left: 15px;
        background: transparent;
        border: 1px solid $c-white;
      }
    }
  }

  .Table {
    margin-top: 15px;
    &__row {
      position: relative;
    }

    &__delete-row {
      position: absolute;
      top: 0;
      left: 0;
      background: #000;
    }

    .action-column {
      width: 35%;
      @include only-xl() {
        width: auto;
      }
    }
  }

  .modalSend__payoff {
    text-align: left;
    margin: 30px 0 0 0;
    font-size: 14px;
  }

  .download-s3-btn {
    color: $c-blue;
    font-size: 105px;
  }
  .download-s3-p {
    color: $c-blue;
  }
}
</style>
